const farmsInitialState = {
  loading: false,
  firstLoad: true,
  farms: [
    {
      pid: 2,
      token: 'banksy',
      quoteToken: 'wbnb',
      isTokenOnly: false,
      name: 'BANKSY-WBNB LP',
      swap: 'PancakeSwap',
    },
    {
      pid: 1,
      token: 'banksy',
      quoteToken: 'busd',
      isTokenOnly: false,
      name: 'BANKSY-BUSD LP',
      swap: 'PancakeSwap',
    },
    {
      pid: 3,
      token: 'wbnb',
      quoteToken: 'busd',
      isTokenOnly: false,
      name: 'WBNB-BUSD LP',
      swap: 'PancakeSwap',
    },
    {
      pid: 4,
      token: 'usdt',
      quoteToken: 'busd',
      isTokenOnly: false,
      name: 'USDT-BUSD LP',
      swap: 'PancakeSwap',
    },
    {
      pid: 5,
      token: 'usdc',
      quoteToken: 'busd',
      isTokenOnly: false,
      name: 'USDC-BUSD LP',
      swap: 'PancakeSwap',
    },
    // POOLS
    {
      pid: 0,
      token: 'banksy',
      quoteToken: 'busd',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'PancakeSwap',
    },
    {
      pid: 6,
      token: 'wbnb',
      quoteToken: 'busd',
      isTokenOnly: true,
      name: 'WBNB',
      swap: 'PancakeSwap',
    },
    {
      pid: 7,
      token: 'busd',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'BUSD',
      swap: 'PancakeSwap',
    },
    {
      pid: 8,
      token: 'usdt',
      quoteToken: 'busd',
      isTokenOnly: true,
      name: 'USDT',
      swap: 'PancakeSwap',
    },
    {
      pid: 9,
      token: 'twt',
      quoteToken: 'busd',
      isTokenOnly: true,
      name: 'TWT',
      swap: 'PancakeSwap',
    },
  ],
}

export default farmsInitialState;
