import React from 'react';
import { Redirect, Route, Switch  } from 'react-router-dom';

// Home Section
import DashboardPage from '../../../pages/dashboard/DashboardPage';
import PresalePage from '../../../pages/presale/PresalePage';
import LiquidityToolBoxPage from '../../../pages/liquidityToolBox/LiquidityToolBoxPage';
import AntiBotPage from '../../../pages/antiBot/AntiBotPage';
// Earn Section
import FarmsPage from '../../../pages/farms/FarmsPage';
import PoolsPage from '../../../pages/pools/PoolsPage';
import VaultsPage from '../../../pages/vaults/VaultsPage';
// NFT Section
import NftHomePage from '../../../pages/nft/NftHomePage';
// import NftMergePage from '../../../pages/nft/NftMergePage';
import NftSalePage from '../../../pages/nft/NftSalePage';
import NftAirdropPage from '../../../pages/nft/NftAirdropPage';
import NftRankingPage from '../../../pages/nft/NftRankingPage';
import NftPage from '../../../pages/nft/NftPage';

import './Content.css';

const Content = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/dashboard">
        <DashboardPage />
      </Route>
      <Route path="/timeline">
        <PresalePage />
      </Route>
      <Route path="/toolbox">
        <LiquidityToolBoxPage />
      </Route>
      <Route path="/anti-bot">
        <AntiBotPage />
      </Route>
      <Route path="/farms">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <FarmsPage isTokenOnly={ false } />
        </React.Suspense>
      </Route>
      <Route path="/pools">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <FarmsPage isTokenOnly={ true } />
        </React.Suspense>
      </Route>
      <Route path="/walls">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <PoolsPage />
        </React.Suspense>
      </Route>
      <Route path="/vaults">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <VaultsPage />
        </React.Suspense>
      </Route>
      <Route path="/my-nfts">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftHomePage />
        </React.Suspense>
      </Route>
      {/* <Route path="/nft-merge">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftMergePage />
        </React.Suspense>
      </Route> */}
      <Route path="/nft-sale">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftSalePage />
        </React.Suspense>
      </Route>
      <Route path="/nft-airdrop">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftAirdropPage />
        </React.Suspense>
      </Route>
      <Route path="/nft-ranking">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftRankingPage />
        </React.Suspense>
      </Route>
      <Route path="/nfts/:tokenId">
        <React.Suspense fallback={ <div>Loading...</div> }>
          <NftPage />
        </React.Suspense>
      </Route>

      <Route path="*">
        <Redirect push to="/" />
      </Route>
    </Switch>
  );
}

export default Content;
