import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { removeLiquidity, approveLiquidityToolBox, fetchLiquidityToolBoxRemove } from '../../../blockchain/liquidityToolBox';
import { ToastContext } from '../../../context/toast';
import { getAddress, getLpAddress, nativeTokenName, networkTokenName, stableTokenName } from '../../../utils/commons';

const names = {
  [process.env.REACT_APP_NATIVE_TOKEN]: nativeTokenName,
  [process.env.REACT_APP_NETWORK_TOKEN]: networkTokenName,
  [process.env.REACT_APP_STABLE_TOKEN]: stableTokenName,
};

const Remove = ({ isActive }) => {
  const { addToast } = useContext(ToastContext);
  const token = process.env.REACT_APP_NATIVE_TOKEN;
  const [quote, setQuote] = useState(process.env.REACT_APP_NETWORK_TOKEN);
  const [lpValue, setLpValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);

  const [addState, setLiquidityToolBoxState] = useState({
    loading: false,
    firstLoad: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncLiquidityToolBox = async () => {
      setLiquidityToolBoxState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchLiquidityToolBoxRemove(token, quote);
      setLiquidityToolBoxState(prevState => ({ ...prevState, ...rs, loading: false, firstLoad: false }));
    }

    const intervalId = setInterval(syncLiquidityToolBox, 5000);

    syncLiquidityToolBox();

    return () => clearInterval(intervalId);
  }, [setLiquidityToolBoxState, token, quote]);

  const handleApprove = async (address) => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveLiquidityToolBox(getLpAddress(token, address));
      await tx.wait();
      addToast((
        <>
          Token Approve succeeded <a href={ `https://bscscan.com/tx/${tx.hash}` } target="_blank" rel="noreferrer">see more</a>.
        </>
      ), 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Token Approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleSupply = async () => {
    setPendingTx(true);
    let tx;
    try {
      const lpVal = new BigNumber(lpValue).times(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)).toFixed();
      tx = await removeLiquidity(getAddress(quote), lpVal);
      await tx.wait();
      setLpValue('');
      addToast((
        <>
          LP remove success <a href={ `https://bscscan.com/tx/${tx.hash}` } target="_blank" rel="noreferrer">see more</a>.
        </>
      ), 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Transaction failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleQuoteChange = (evt) => {
    const { target } = evt;
    setLpValue(target.value);
  }

  const handleQuoteSelect = (evt) => {
    const { target } = evt;
    setQuote(target.value);
    setLpValue('');
  }

  const setLpMax = (mul) => {
    const lpVal = new BigNumber(addState.userLpBalance).times(mul);

    setLpValue(lpVal.toFixed(Number(process.env.REACT_APP_DECIMALS)));
  }

  const renderAction = () => {
    if (new BigNumber(addState.userLpAllowance).eq(0)) {
      return (
        <button
          onClick={ () => handleApprove(quote) }
          type="button"
          disabled={ pendingTx }
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        >
          Approve { names[token] }-{ names[quote] }
        </button>
      );
    }

    if (new BigNumber(lpValue).gt(addState.userLpBalance)) {
      return (
        <button disabled className="button is-primary is-fullwidth">
          { names[token] }-{ names[quote] } insufficient balance.
        </button>
      );
    }

    return (
      <button
        onClick={ handleSupply }
        disabled={ pendingTx || new BigNumber(lpValue || 0).eq(0) }
        type="button"
        className="button is-primary is-fullwidth"
      >
        Remove
      </button>
    );
  }

  return (
    <div className={ `px-3 pb-3 ${ isActive ? '' : 'is-hidden' }` }>
      <div className="buttons is-right mb-0">
        <button className="button is-success is-small is-outlined" onClick={ () => setLpMax(0.25) }>
          25%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setLpMax(0.5) }>
          50%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setLpMax(0.75) }>
          75%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setLpMax(1) }>
          100%
        </button>
        <button
          onClick={ () => setLpMax(1) }
          type="button"
          className="button is-text"
        >
          { new BigNumber(addState.userLpBalance).toFormat(12) } available
        </button>
      </div>
      <div className="field has-addons mb-5">
        <p className="control">
          <span className="select">
            <select value={ quote } onChange={ handleQuoteSelect }>
              <option value={ process.env.REACT_APP_NETWORK_TOKEN }>{ nativeTokenName }-{ networkTokenName }</option>
              <option value={ process.env.REACT_APP_STABLE_TOKEN }>{ nativeTokenName }-{ stableTokenName }</option>
            </select>
          </span>
        </p>
        <p className="control is-expanded">
          <input value={ lpValue } onChange={ handleQuoteChange } className="input has-text-right" type="text" placeholder="0" />
        </p>
      </div>
      { renderAction() }
    </div>
  );
}

export default Remove;
