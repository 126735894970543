const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    {
      pid: 2,
      address: '0x1e7404C60AC47Be1Ed0970AE0A6715ee1466C14c',
      stakedToken: 'banksy',
      rewardToken: 'twt',
      name: 'BANKSY TWT WALL',
      projectLink: 'https://trustwallet.com/',
      category: 'CORE',
      version: 'v2',
      stakedTokenDefaultPrice: 7.50,
    },
    {
      pid: 1,
      address: '0x1c29EEbB7b436bc873362752e9d6C0A046f2cE7b',
      stakedToken: 'preBanksy',
      rewardToken: 'twt',
      name: 'DEGO TWT WALL',
      projectLink: 'https://trustwallet.com/',
      category: 'CORE',
      version: 'v2',
      stakedTokenDefaultPrice: 5.00,
    },
  ],
};

export default poolInitialState;
