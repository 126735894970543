import BigNumber from "bignumber.js";
import { ethers } from "ethers";

const abi = [
  // DegoToken
  'error PresaleHasntStartedYet(uint256 startTime)',
  'error PresaleEnded(uint256 endTime)',
  'error NoTokensRemaining()',
  'error InsufficientBalance(uint256 usdSpent)',
  'error TokenAmountCantBeZero()',
  'error PresaleAlreadyStarted(uint256 startTime)',
  'error StartTimeMustBeInFuture(uint256 startTime)',
  'error WorngTimes(uint256 startTime, uint256 endTime)',
  'error PresaleMustBeOver(uint256 endTime)',
  'error PresaleAlreadyBurned()',
  // DegoSwap
  'error PreTokenAndTokenAreTheSame(address degoAddress, address banksyAddress)',
  'error SwapHasntStartedYet(uint256 startTime)',
  'error InsufficientBalance(uint256 availabme, uint256 amount)',
  'error UnableToTransfer()',
  'error PresaleMustBeOver(uint256 endTime)',
  'error SwapAlreadyBurned()',
  'error BurnSwapIsTooHigh()',
  'error SwapAlreadyStarted(uint256 startTime)',
  'error StartTimeMustBeInFuture(uint256 startTime)',
  // masterchef
  'error DepositFeeIsTooHigh(uint256 depositFeeBP)',
  'error HarvestIntervalIsTooHigh(uint256 harvestInterval)',
  'error InvalidBatchSize()',
  'error AmountMustBeGreaterThanZero()',
  'error WithdrawAmountIsTooHigh(uint256 amount, uint256 available)',
  'error NFTAlreadyAdded()',
  'error UserIsNotNFTOwner()',
  'error NoNFTAdded()',
  'error InvalidAddress(address addr)',
  'error FarmAlreadyStarted()',
  'error StartTimeMusBeInTheFuture(uint256 startTime)',
  'error InvalidEmissionRate(uint256 emissionRate)',
  'error InvalidExperienceRate(uint256 experienceRate)',
  'error NFTIsNotInContract(uint256 nftId)',
];

const errorMessage = (error) => {
  if (typeof error.data === 'undefined') {
    return error.message;
  }

  const int = new ethers.utils.Interface(abi);
  const error_data = error.data.data;

  try {
    const decoded = int.getError(error_data.substring(0, 10), error_data);
    const errorResult = int.decodeErrorResult(error_data.substring(0, 10), error_data);

    const params = decoded.inputs.map(input => {
      if (input.type === 'uint256') {
        return input.name +' = '+ new BigNumber(errorResult[input.name]._hex).toFormat();
      }

      return input.name +' = '+ errorResult[input.name];
    });

    return `${decoded.name}(${params.join(', ')})`;
  } catch (e) {
    console.log('e', e);
  }

  return error.data?.message || error.message;
}

export default errorMessage;
