import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';

import { fetchNft } from '../../blockchain/nft';
import { useParams } from 'react-router-dom';
import NftCard from './components/NftCard';

const NftPage = () => {
  const { tokenId } = useParams();

  const [nftState, setNftState] = useState({
    loading: true,
    item: {
      pid: tokenId,
      power: 0,
      generation: 0,
      experience: '0',
      mergeCount: 0,
      ownerOf: false
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncNft = async () => {
      const rs = await fetchNft(tokenId);
      setNftState(prevState => ({ ...prevState, ...rs }));
    }

    syncNft();
  }, [setNftState, tokenId]);

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-4.png")' }} />
      <NftNav />
      <header className="hero is-relative">
      <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-4.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">NFT Artwork</p>
              {nftState.loading ? null : (
                <p className="subtitle">
                  <a className="has-text-primary" href={`https://opensea.io/assets/bsc/0x9c26e24ac6f0ea783ff9ca2bf81543c67cf446d2/${nftState.item.pid}`} target="_blank" rel="noreferrer">
                    {nftState.item.ownerOf ? (
                      <span>Buy !</span>
                    ) : (
                      <span>Visit Collection</span>
                    )}
                    <span className="icon is-small ml-1">
                      <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
                    </span>
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              {nftState.loading ? (
                <div className="has-text-centered">
                  <span className="icon-text is-align-items-center">
                    <span className="icon is-large">
                      <FontAwesomeIcon icon={ faCog } spin size="2x" />
                    </span>
                    <span>Loading...</span>
                  </span>
                </div>
              ) : (
                <NftCard
                  nftData={nftState.item}
                />
              )}
            </div>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />
    </>
  );
}

export default NftPage;
