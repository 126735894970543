import React, { useContext, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { faCheck, faCog, faExternalLinkAlt, faStopwatch, faTractor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeNav from '../home/components/HomeNav';
import PreBanksyBuyModal from './PreBanksyBuyModal';
import NftCard from '../nft/components/NftCard';

import { fetchPreSale, approveBuyPreBanksy, approveSwapPreBanksy, swapPreBanksyForBanksy } from '../../blockchain/preSale';

import { WalletContext } from '../../context/wallet';
import { ToastContext } from '../../context/toast';
import { swapAddress } from '../../utils/commons';

const PresalePage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [width, setWidth] = useState(window.innerWidth);

  const [preBanksyStarted, setPreBanksyStarted] = useState(false);
  const [preBanksyEnded, setPreBanksyEnded] = useState(false);
  const [provideLiquidityStarted, setProvideLiquidityStarted] = useState(false);
  const [preBanksySwapStarted, setPreBanksySwapStarted] = useState(false);
  const [masterChefStarted, setMasterChefStarted] = useState(false);
  const [nftSale0Started, setNftSale0Started] = useState(false);
  // const [nftSale1Started, setNftSale1Started] = useState(false);
  // const [nftSale2Started, setNftSale2Started] = useState(false);
  const [nftAirdropStarted, setNftAirdropStarted] = useState(false);
  const [wall0Started, setWall0Started] = useState(false);
  const [wall1Started, setWall1Started] = useState(false);

  const [pendingBuyTx, setPendingBuyTx] = useState(false);
  const [pendingSwapTx, setPendingSwapTx] = useState(false);

  const [preSaleState, setPreSaleState] = useState({
    firstLoad: true,
    preBanksyStartTime: 0,
    preBanksyEndTime: 0,
    preBanksyPerAccountMaxTotal: 0,
    preBanksyRemaining: 0,
    currentStage: 0,
    salePrice: 0,
    preBanksyDecimals: 0,
    preBanksyBalance: 0,
    userPreBanksySwapAllowance: 0,
    userPreBanksyBalance: 0,
    earlyAccessTwoTime: 0,
    earlyAccessThreeTime: 0,

    preBanksySwapStartTime: 0,

    preBanksySwapBalance: 0,
    nativeTokenDecimals: 0,
    tokenDecimals: 0,
    userPreBanksyAllowance: 0,
    userTokenBalance: 0,
    userNativeTokenBalance: 0,

    masterChefStartTime: 0,

    nftSale0StartTime: 0,
    nftSale0BalanceOf: 0,
    nftSale1StartTime: 0,
    nftSale1BalanceOf: 0,
    nftSale2StartTime: 0,
    nftSale2BalanceOf: 0,
    nftSaleCurrent: 0,

    nftAirdropStartTime: 0,
    nftAirdropBalanceOf: 0,

    soldOut: false,
  });
  const [isPreBanksyBuyModalActive, setIsPreBanksyBuyModalActive] = useState(false);

  const handlePreBanksyBuyModalClose = () => setIsPreBanksyBuyModalActive(false);
  const handlePreBanksyBuyModalOpen = () => setIsPreBanksyBuyModalActive(true);

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncPreSale = async () => {
      setPreSaleState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchPreSale();
      setPreSaleState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncPreSale, 5000);

    syncPreSale();

    return () => clearInterval(intervalId);
  }, [setPreSaleState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const preBanksyStartTime = new BigNumber(preSaleState.preBanksyStartTime);
      const preBanksyEndTime = new BigNumber(preSaleState.preBanksyEndTime);
      const preBanksySwapStartTime = new BigNumber(preSaleState.preBanksySwapStartTime);
      const masterChefStartTime = new BigNumber(preSaleState.masterChefStartTime);
      const nftSale0StartTime = new BigNumber(preSaleState.nftSale0StartTime);
      // const nftSale1StartTime = new BigNumber(preSaleState.nftSale1StartTime);
      // const nftSale2StartTime = new BigNumber(preSaleState.nftSale2StartTime);
      const wall0StartTime =  new BigNumber(process.env.REACT_APP_WALL_0_START);
      const wall1StartTime =  new BigNumber(process.env.REACT_APP_WALL_1_START);
      const nftAirdropStartTime = new BigNumber(preSaleState.nftAirdropStartTime);
      const launchApp = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setPreBanksyStarted(prevState => prevState || (preBanksyStartTime.gt(0) && preBanksyStartTime.lt(currentTime)));
      setPreBanksyEnded(prevState => prevState || (preBanksyEndTime.gt(0) && preBanksyEndTime.lt(currentTime)));
      setPreBanksySwapStarted(prevState => prevState || (preBanksySwapStartTime.gt(0) && preBanksySwapStartTime.lt(currentTime)));
      setMasterChefStarted(prevState => prevState || (masterChefStartTime.gt(0) && masterChefStartTime.lt(currentTime)));
      setNftSale0Started(prevState => prevState || (nftSale0StartTime.gt(0) && nftSale0StartTime.lt(currentTime)));
      // setNftSale1Started(prevState => prevState || (nftSale1StartTime.gt(0) && nftSale1StartTime.lt(currentTime)));
      // setNftSale2Started(prevState => prevState || (nftSale2StartTime.gt(0) && nftSale2StartTime.lt(currentTime)));
      setWall0Started(prevState => prevState || (wall0StartTime.gt(0) && wall0StartTime.lt(currentTime)));
      setWall1Started(prevState => prevState || (wall1StartTime.gt(0) && wall1StartTime.lt(currentTime)));
      setNftAirdropStarted(prevState => prevState || (nftAirdropStartTime.gt(0) && nftAirdropStartTime.lt(currentTime)));
      setProvideLiquidityStarted(prevState => prevState || (launchApp.gt(0) && launchApp.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [preSaleState, setPreBanksyStarted, setPreBanksyEnded, setPreBanksySwapStarted, setMasterChefStarted, setNftSale0Started, setWall0Started, setWall1Started, setNftAirdropStarted]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleBuyApprove = async () => {
    setPendingBuyTx(true);
    let tx;
    try {
      tx = await approveBuyPreBanksy('busd');
      await tx.wait();
      addToast('PreBanksy Token approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy Token approve failed', 'is-danger');
    }

    setPendingBuyTx(false);
  }

  const renderUnlockOrApproveOrBuyButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = !preBanksyStarted || preBanksyEnded;

    if (new BigNumber(preSaleState.userPreBanksyAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading() || pendingBuyTx || preSaleState.soldOut || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingBuyTx ? 'is-loading' : ''}` }
          onClick={ handleBuyApprove }
        >
          APPROVE BUSD
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() || isNotActive || preSaleState.soldOut }
        className="button is-primary is-fullwidth"
        onClick={ () => handlePreBanksyBuyModalOpen() }
      >
        Buy
      </button>
    );
  }

  const handleSwapApprove = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await approveSwapPreBanksy();
      await tx.wait();
      addToast('PreBanksy token Swap approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy token Swap approve failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const handleSwap = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await swapPreBanksyForBanksy();
      await tx.wait();
      addToast('Dego Swap succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Dego Swap failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const renderUnlockOrApproveOrSwapButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = !preBanksySwapStarted;

    if (new BigNumber(preSaleState.userPreBanksySwapAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading() || pendingSwapTx || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
          onClick={ handleSwapApprove }
        >
          APPROVE DEGO
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() || pendingSwapTx || isNotActive || new BigNumber(preSaleState.userPreBanksyBalance).eq(0) }
        className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
        onClick={ () => handleSwap() }
      >
        SWAP
      </button>
    );
  }

  const isLoading = () => preSaleState.firstLoad && preSaleState.loading;

  const renderRemainingOrSoldOut = () => {
    const rm = new BigNumber(preSaleState.preBanksyRemaining)
      .div(new BigNumber(10).pow(preSaleState.preBanksyDecimals));

    return rm.eq(0) ? 'SOLD OUT' : rm.toFormat(2);
  }

  const preBanksyEndCountdown = useMemo(() => {
    // const currentStage = new BigNumber(preSaleState.currentStage);

    // if (currentStage.eq(3)) {
      return (<Countdown date={ new BigNumber(preSaleState.preBanksyEndTime).times(1000).toNumber() } />);
    // }

    // if (currentStage.eq(2)) {
    //   return (<Countdown date={ new BigNumber(preSaleState.earlyAccessThreeTime).times(1000).toNumber() } />);
    // }

    // return (<Countdown date={ new BigNumber(preSaleState.earlyAccessTwoTime).times(1000).toNumber() } />);
  // }, [preSaleState.currentStage, preSaleState.preBanksyEndTime, preSaleState.earlyAccessThreeTime, preSaleState.earlyAccessTwoTime]);
  }, [preSaleState.preBanksyEndTime]);

  const buyBUSD = () => {
    return swapAddress({
      token: 'busd',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'BUSD',
      swap: 'PancakeSwap',
    });
  }

  const nftSaleTitle = () => {
    if (isLoading()) {
      return null;
    }

    if (preSaleState.nftSaleCurrent < 2) {
      return `EARLY ACCESS ${preSaleState.nftSaleCurrent + 1}`;
    }

    return 'PUBLIC ACCESS';
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
      <HomeNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Banksy DAO Timeline</p>
              <p className="subtitle">
                Check our Timeline in <span className="has-text-primary">Realtime</span>. Our contracts executes as <span className="has-text-primary">DAO</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className={ `timeline ${width > 768 ? 'is-centered' : null}` }>
            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">
                { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <>Start</> }
              </span>
            </header>

            <div className={ `timeline-item ${isLoading() || !nftSale0Started ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftSale0Started ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftSale0Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 12th, 15:00 UTC
                </p>
                <p>3D SMART NFT SALE - { nftSaleTitle() }</p>
                <div>
                  <NftCard nftData={{
                    pid: 1000,
                    experience: 287,
                    generation: 3,
                    power: 27
                  }} hideDetails />
                  <Link to="/nft-sale" className="button is-primary is-fullwidth">
                    BUY - ONLY $5
                  </Link>
                </div>
                <p className="has-text-info mt-3">* { isLoading() ? (<FontAwesomeIcon icon={ faCog } spin />) : new BigNumber(preSaleState[`nftSale${preSaleState.nftSaleCurrent}BalanceOf`]).toFormat(0) } 3D SMART NFTs remaining</p>
              </div>
            </div>

            <div id="pre-sale" className={ `timeline-item ${isLoading() || !preBanksyStarted ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !preBanksyStarted ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !preBanksyStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 13th, 15:00 UTC
                </p>
                <div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <p className="card-header-title">DEGO TOKEN - PRESALE</p>
                    </div>
                    <div className="card-content">
                      DEGO Remaining: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ renderRemainingOrSoldOut() }</strong> }<br />
                      Your DEGO balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userPreBanksyBalance).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> }<br />
                      Your BUSD balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">${ new BigNumber(preSaleState.userTokenBalance).div(new BigNumber(10).pow(preSaleState.tokenDecimals)).toFormat(2) }</strong> } <a href={ buyBUSD() } className="ml-3" target="_blank" rel="noreferrer"><span>buy</span><span className="icon"><FontAwesomeIcon icon={ faExternalLinkAlt } /></span></a><br />
                      You can buy up to: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.preBanksyPerAccountMaxTotal).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> } DEGO<br /><br />
                      { renderUnlockOrApproveOrBuyButton() }
                      <h5 className="is-size-5 has-text-bold has-text-primary has-text-centered mt-3">Only ${ new BigNumber(preSaleState.salePrice).toFormat(2) } per Token</h5>
                    </div>
                    <div className="card-footer">
                      <p className="card-footer-item">
                        {!isLoading() && !preBanksyStarted ? (
                          <>
                            <span className="mr-1">Countdown until Start</span> <Countdown date={ new BigNumber(preSaleState.preBanksyStartTime).times(1000).toNumber() } />
                          </>
                        ) : null}
                        {preBanksyStarted && !preBanksyEnded ? (
                          <>
                            <span className="mr-1">
                              Countdown until End
                            </span>
                            { preBanksyEndCountdown }
                          </>
                        ) : null}
                        {!isLoading() && preBanksyEnded ? (
                          <span>PRE-SALE ENDED</span>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  {/* <div className="content">
                    <p className="has-text-info">* Buy DEGO token using BUSD</p>
                    <ol>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(1) ? 'has-text-success' : ''} ${new BigNumber(preSaleState.currentStage).gt(1) ? 'is-line-through' : ''}` }>EARLY ACCESS 1, Dec 13th 15:00 UTC, $20</li>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(2) ? 'has-text-success' : ''} ${new BigNumber(preSaleState.currentStage).gt(2) ? 'is-line-through' : ''}` }>EARLY ACCESS 2, Dec 14th 15:00 UTC, $23</li>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(3) ? 'has-text-success' : ''} ${!isLoading() && preBanksyEnded ? 'is-line-through' : ''}` }>EARLY ACCESS 3, Dec 15th 15:00 UTC, $25</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || !wall0Started ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || !wall0Started ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !wall0Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 13th, 19:00 UTC
                </p>
                <p>DEGO WALL (stake presale token and earn TWT)</p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/dego.png" alt="DEGO" />
                    </figure>
                    <Link to="/walls" disabled={ isLoading() } className="button is-primary">
                      FINISHED
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={ `timeline-item ${isLoading() || !nftSale1Started ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftSale1Started ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftSale1Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 14th, 9:00 UTC
                </p>
                <p>NFT SALE - EARLY ACCESS 2</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 3,
                    power: 27
                  }} hideDetails />
                  <button type="button" className="button is-primary is-fullwidth">
                    BUY - ONLY $7.5
                  </button>
                </div>
              </div>
            </div> */}

            <div className={ `timeline-item ${ isLoading() || !preBanksyEnded ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || !preBanksyEnded ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !preBanksyEnded ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 16th, 15:00 UTC
                </p>
                <p className="mr-5">
                  Presale End
                </p>
                <figure className="image is-128x128 mb-3">
                  <img src="/images/dego.png" alt="DEGO" />
                </figure>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !provideLiquidityStarted ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !provideLiquidityStarted ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !provideLiquidityStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 17th, 15:00 UTC
                </p>
                <p>Initial Liquidity Provided</p>
                <div className="p-3">
                  <figure className="image is-128x128 mb-3">
                    <img src="/images/farms/banksy.png" alt="BANKSY" />
                  </figure>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !preBanksySwapStarted ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !preBanksySwapStarted ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !preBanksySwapStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 17th, 16:00 UTC
                </p>
                <div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <p className="card-header-title">BANKSY Redeem</p>
                    </div>
                    <div className="card-content">
                      BANKSY Remaining: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.preBanksySwapBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }<br />
                      Your BANKSY balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userNativeTokenBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }<br />
                      You can swap for up to: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userPreBanksyBalance).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> } BANKSY<br />
                      Rate: 1 : 1 Swap<br /><br />
                      { renderUnlockOrApproveOrSwapButton() }
                    </div>
                    {!isLoading() && !preBanksySwapStarted ? (
                      <div className="card-footer">
                        <p className="card-footer-item">
                          <span className="mr-1">Countdown until Start</span> <Countdown date={ preSaleState.preBanksySwapStartTime * 1000 } />
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="has-text-info">
                    * Swap your DEGO per BANKSY.
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || !wall1Started ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || !wall1Started ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !wall1Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 17th, 19:00 UTC
                </p>
                <p>Banksy WALL (stake Banksy token and earn TWT)</p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/farms/banksy.png" alt="Banksy" />
                    </figure>
                    <Link to="/walls" disabled={ isLoading() } className="button is-primary">
                      Stake on Wall
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={ `timeline-item ${isLoading() || !nftSale2Started ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftSale2Started ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftSale2Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Jan 10th, 12:00 UTC
                </p>
                <p>NFT SALE - EARLY ACCESS 3</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 0,
                    power: 27
                  }} hideDetails />
                  <button type="button" className="button is-primary is-fullwidth" disabled>
                    SOLD OUT
                  </button>
                </div>
              </div>
            </div> */}

            <div className={ `timeline-item ${isLoading() || !nftAirdropStarted ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftAirdropStarted ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftAirdropStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 20th, 12:00 UTC
                </p>
                <p>ARTWORK 3D SMART NFT AIRDROP</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 3,
                    power: 27
                  }} hideDetails />
                  <Link to="/nft-airdrop" className="button is-primary is-fullwidth mb-3" disabled={ !nftAirdropStarted }>
                    Open 3D SMART NFT Airdrop
                  </Link>
                  <div className="has-text-info">
                    * Only 10 3D SMART NFT for AIRDROP.<br />
                    * { isLoading() ? (<FontAwesomeIcon icon={ faCog } spin />) : new BigNumber(preSaleState.nftAirdropBalanceOf).toFormat(0) } 3D SMART NFTs remaining.
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !masterChefStarted ? faTractor : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 20th, 15:00 UTC
                </p>
                <p className="mr-4">Farming Start</p>
                <figure className="image is-128x128 mb-3">
                  <img src="/images/farms/banksy.png" alt="BANKSY" />
                </figure>
              </div>
            </div>

            {/* <div className={ `timeline-item ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !masterChefStarted ? faSync : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Jun 9th, 15:00 UTC
                </p>
                <p>Vaults Start</p>
              </div>
            </div> */}

            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">To the Moon!</span>
            </header>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />
      <PreBanksyBuyModal
        preSaleState={ preSaleState }
        isModalActive={ isPreBanksyBuyModalActive }
        onModalClose={ handlePreBanksyBuyModalClose }
      />
    </>
  );
}

export default PresalePage;
