import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { faCog, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftBuyModal from './NftBuyModal';
import NftInfo from './components/NftInfo';

import { WalletContext } from '../../context/wallet';
import { ToastContext } from '../../context/toast';

import { fetchNftSale, approveNftSale } from '../../blockchain/nft';
import { swapAddress } from '../../utils/commons';

const NftSalePage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(0);
  const [started, setStarted] = useState(false);
  const [nftSaleState, setNftSaleState] = useState({
    loading: false,
    firstLoad: true,
    nftCardsRemaining: 0,
    userNftBalance: 0,
    tokenDecimals: 0,
    userBalance: 0,
    userAllowance: 0,
    startTime: 0,
    salePrice: 0,
    nftCardPerAccountMaxTotal: 0,
    userNftCardTotally: 0,
    current: 0,
  });
  const [isNftBuyModalActive, setIsNftBuyModalActive] = useState(false);

  const handleNftBuyModalClose = () => setIsNftBuyModalActive(false);
  const handleNftBuyModalOpen = () => setIsNftBuyModalActive(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncNftSale = async () => {
      setNftSaleState(prevState => ({ ...prevState, loading: true }));
      const nftSaleResult = await fetchNftSale();
      setNftSaleState(prevState => ({ ...prevState, ...nftSaleResult, loading: false }));
    }

    const intervalId = setInterval(syncNftSale, 5000);

    syncNftSale();

    return () => clearInterval(intervalId);
  }, [setNftSaleState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(nftSaleState.startTime);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [nftSaleState.startTime, setStarted]);

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveNftSale('busd', nftSaleState.current);
      await tx.wait();
      addToast('NFT Sale token approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Sale token approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderUnlockApproveOrBuyButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    if (!isLoading() && new BigNumber(nftSaleState.nftCardsRemaining).eq(0)) {
      return (
        <button type="button" disabled className="button is-primary is-fullwidth">SOLD OUT</button>
      );
    }

    if (new BigNumber(nftSaleState.userAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ pendingTx || isLoading() || !started }
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
          onClick={ handleApprove }
        >
          APPROVE BUSD
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() || !started }
        className="button is-primary is-fullwidth"
        onClick={ () => handleNftBuyModalOpen() }
      >
        Buy
      </button>
    );
  }

  const isLoading = () => nftSaleState.firstLoad && nftSaleState.loading;

  const title = () => {
    if (nftSaleState.firstLoad) {
      return <FontAwesomeIcon icon={ faCog } spin />
    }

    if (nftSaleState.current === 0) {
      return 'Early Access 1 (50% off)';
    }

    if (nftSaleState.current === 1) {
      return 'Early Access 2 (50% off)';
    }

    return 'Public Access';
  }

  const buyBUSD = () => {
    return swapAddress({
      token: 'busd',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'BUSD',
      swap: 'PancakeSwap',
    });
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
      <NftNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">NFT ArtWorks Sale</p>
              <p className="subtitle"><span className="has-text-primary">3D SMART NFT's ArtWorks</span> power will be unique and generated randomly.</p>
              <p className="subtitle">
                <a href="https://opensea.io/collection/banksy-world-cup" target="_blank" rel="noreferrer" className="has-text-primary">
                  <span>Visit the Collection</span>
                  <span className="icon is-small ml-1">
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
                  </span>
                </a>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className="columns is-multiline is-justify-content-center">
            <div className="column is-half-desktop">
              <article className="message">
                <div className="message-body">
                  <p className="title">3D SMART NFT SALE<br />{title()}</p>
                  <div className="level is-mobile mb-1">
                    <div className="level-left">
                      <div className="level-item">
                        NFTs Remaining:
                      </div>
                    </div>
                    <div className="level-left">
                      <div className="level-item">
                        <h6 className="has-text-weight-semibold has-text-primary is-size-6">
                          { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : (new BigNumber(nftSaleState.nftCardsRemaining).eq(0) ? 'SOLD OUT' : new BigNumber(nftSaleState.nftCardsRemaining).toFormat(0)) }
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="level is-mobile mb-1">
                    <div className="level-left">
                      <div className="level-item">
                        Your NFT balance:
                      </div>
                    </div>
                    <div className="level-left">
                      <div className="level-item">
                        <h6 className="has-text-weight-semibold has-text-primary is-size-6">{ isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : new BigNumber(nftSaleState.userNftBalance).toFormat(0) }</h6>
                      </div>
                    </div>
                  </div>
                  <div className="level is-mobile mb-1">
                    <div className="level-left">
                      <div className="level-item">
                        Your <a href={ buyBUSD() } className="mx-1" target="_blank" rel="noreferrer"><span>BUSD</span></a> balance:
                      </div>
                    </div>
                    <div className="level-left">
                      <div className="level-item">
                        <h6 className="has-text-weight-semibold has-text-primary is-size-6">{ isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : new BigNumber(nftSaleState.userBalance).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(3) }</h6>
                      </div>
                    </div>
                  </div>
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        You can buy up to:
                      </div>
                    </div>
                    <div className="level-left">
                      <div className="level-item">
                        <h6 className="has-text-weight-semibold has-text-primary is-size-6">{ isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : new BigNumber(nftSaleState.nftCardPerAccountMaxTotal).toFormat(0) } NFTs</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="card mb-3">
                <div className="card-header">
                  <p className="card-header-title">
                    BUY NFT with <a href={ buyBUSD() } className="ml-1" target="_blank" rel="noreferrer"><span>BUSD</span></a>, only <strong className="has-text-primary ml-1">${ new BigNumber(nftSaleState.salePrice).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(2) }</strong>
                  </p>
                </div>
                <div className="card-content">
                  { renderUnlockApproveOrBuyButton() }
                </div>
                {!nftSaleState.firstLoad && !started ? (
                  <div className="card-footer">
                    <p className="card-footer-item has-text-danger">
                      <span className="mr-1">Countdown until Start</span> <Countdown date={ new BigNumber(nftSaleState.startTime).times(1000).toNumber() } />
                    </p>
                  </div>
                ) : null}
              </div>
              <NftInfo />
            </div>
            <div className="column is-half-desktop">
              <NftCard nftData={{
                pid: 1000,
                experience: 287,
                generation: 3,
                power: 27,
                boostStake: 12.18,
              }} />
            </div>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />

      <NftBuyModal
        nftSaleState={ nftSaleState }
        isNftBuyModalActive={ isNftBuyModalActive }
        onNftBuyModalClose={ handleNftBuyModalClose }
      />
    </>
  );
}

export default NftSalePage;
