import { connect } from '../blockchain/commons';

const WalletModal = ({ isModalActive, onModalClose }) => {
  
  const connectToMetamask = async () => {
    const walletAddress = await connect("METAMASK");
    onModalClose(walletAddress);
  }

  const connectToWalletConnect = async () => {
    const walletAddress = await connect("WALLET_CONNECT");
    onModalClose(walletAddress);
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Connect </p>
          <button className="delete" aria-label="close" onClick={ () => onModalClose(null) }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-right mb-0">
            <button className="button is-success is-small is-outlined" onClick={ connectToMetamask }>
              Metamask
            </button>
            <button className="button is-success is-small is-outlined" onClick={ connectToMetamask }>
              TrustWallet
            </button>
            <button className="button is-success is-small is-outlined" onClick={ connectToWalletConnect }>
              WalletConnect
            </button>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            className="button is-success"
          >
            Confirm
          </button>
          <button className="button" onClick={ () => onModalClose(null) }>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default WalletModal;
