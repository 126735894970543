const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x3b74aeF37EF5745fD8233d0476b9249e3Ad71924');
contractsMainNet.set('treasuryDAO', '0x0f5C40d4F4c814e4db105B8097dCeb26f93739D3');
contractsMainNet.set('liquidityToolBox', '0xDB81380e24521524933fC747FC2BC6D31864B7a9'); //
contractsMainNet.set('treasuryUtil', '0x77351cA200E7Db9EC6265c9f6C6F2c5BdA746D89');
contractsMainNet.set('multiCall', '0x147ad420C011AEE8ee0D90C37Df83124DF894059');

contractsMainNet.set('nftChef', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
contractsMainNet.set('nftSale0', '0x64aB872a2937dE057F21c8e0596C0175FF2084d8');
contractsMainNet.set('nftSale1', '0xffec222ff5820e14680522cD89cE002Bd648720f');
contractsMainNet.set('nftSale2', '0xc25d355c42BB236E7bebC36aa00c7f4B6b5A6f4a');
contractsMainNet.set('nftAirdrop', '0x6695eD00d08c7E6fC4e62e148537291fEbe095df');
contractsMainNet.set('nftMerge', '0x146dFbAa92d995e5dFb64Ab88cCC343A7F07d8A7'); //

contractsMainNet.set('preBanksy', '0x2E1dA07B1046c3F6daFc7EBB11b9F57Be0C525D7');
contractsMainNet.set('preBanksySwap', '0x5B1bd5875e53E2b20e24dBB918382049Ce923Ec5');
// single tokens
contractsMainNet.set('banksy', '0x5022742f9Eadb82471171FdF6dfB33F26E2b0571');
contractsMainNet.set('wbnb', '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c');
contractsMainNet.set('busd', '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');
contractsMainNet.set('usdt', '0x55d398326f99059fF775485246999027B3197955');
contractsMainNet.set('twt', '0x4B0F1812e5Df2A09796481Ff14017e6005508003');
contractsMainNet.set('usdc', '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d');
// LPs
contractsMainNet.set('banksy-wbnb', '0x4FabE34404c2Cc7F1774d058c7c0AB1e445019b5');
contractsMainNet.set('banksy-busd', '0xafb4d4037db54571A1058709C5656208F03c5848');
contractsMainNet.set('wbnb-busd', '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16');
contractsMainNet.set('usdt-busd', '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00');
contractsMainNet.set('usdc-busd', '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1');
contractsMainNet.set('twt-busd', '0x4C7202d93443c9Ed8f6a803F00e2DF18B1f62382');
contractsMainNet.set('twt-wbnb', '0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13');

// Fake
contractsMainNet.set('preBanksy-wbnb', '0x94BcFbE8bb89a23B88E2a2832f069E17717e6fbf'); // for pool#1
// contractsMainNet.set('fdai-ftm', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// contractsMainNet.set('fdai', '0x3573E1C88D66073F1d5d497a12EB7eb796a244CE');
// contractsMainNet.set('fusdc', '0x3064EDEE4ADA222806db307b90A0b7A36B4cbd18');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-ftm', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
