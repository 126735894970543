import React from 'react';
import Countdown from 'react-countdown';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ days, hours, minutes, seconds }) => {
  let klass = 'has-text-primary';
  if (days < 1) {
    if (hours < 6) {
      klass = 'has-text-warning';
    }
    if (hours < 1) {
      klass = 'has-text-danger';
    }
  } else if (days > 7) {
    klass = 'has-text-info';
  }

  return (
    <p className={ `title ${klass}` }>
      { days > 0 ? `${days} days ` : '' }{ pad(hours) }:{ pad(minutes) }:{ pad(seconds) }
    </p>
  );
};

const CountdownSection = () => {
  const conditionalRender = () => {
    const farmingStartAt = Number(process.env.REACT_APP_FARMING_START_AT) * 1000;
    if (Date.now() > farmingStartAt) {
      return null;
    }

    const launchAt = Number(process.env.REACT_APP_LAUNCH_AT) * 1000;

    const presaleAt = Number(process.env.REACT_APP_PRESALE_AT) * 1000;

    return (
      <div className="box">
        <div className="columns">
          <div className="column has-text-centered">
            <Countdown date={ presaleAt } renderer={ renderer } />
            <p className="subtitle">
              Presale CountDown
            </p>
          </div>
          <div className="column has-text-centered">
            <Countdown date={ launchAt } renderer={ renderer } />
            <p className="subtitle">
              Token Launch CountDown
            </p>
          </div>
          <div className="column has-text-centered">
            <Countdown date={ farmingStartAt } renderer={ renderer } />
            <p className="subtitle">
              Farming CountDown
            </p>
          </div>
        </div>
      </div>
    );
  }

  return conditionalRender();
}

export default CountdownSection;
