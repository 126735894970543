import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';

import { nftImage, nftName, genNameClass } from '../../../utils/nft';
import { VrContext } from '../../../context/vr';

const NftCard = ({ nftData, hideDetails = false, ribbon = '', ribbonLink = '#' }) => {
  const { toggleVrModal } = useContext(VrContext);

  const renderVrPreview = () => {
    if (nftData.generation !== 2) {
      return null;
    }

    return (
      <button
        type="button"
        className="button is-text ml-auto"
        onClick={ toggleVrModal }
      >
        <span className="icon">
          <FontAwesomeIcon icon={ faExpandAlt } />
        </span>
      </button>
    );
  }

  return (
    <div className={ `box p-3 is-relative ${nftData.mergeCount > 0 ? 'gradient-border' : ''}` }>
      { ribbon !== '' ? (
        <Link to={ ribbonLink } className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </Link>
      ) : null }
      { nftImage(nftData) }
      <p className="mb-1 is-flex is-align-items-center is-justify-content-center">
        <span className={genNameClass(nftData)}>{ nftName(nftData) }</span>
        { renderVrPreview() }
      </p>
      {hideDetails ? null : (
        <div className="column is-narrow mb-3">
          <div className="field is-grouped is-grouped-multiline">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Generation</span>
                <span className="tag mb-0 is-info">{ nftData.generation }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Experience</span>
                <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).toFormat(0) }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Power</span>
                <span className="tag mb-0 is-primary">{ nftData.power }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Boost Stake</span>
                <span className="tag mb-0 is-danger">{ new BigNumber(nftData.boostStake).toFormat(2) }%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NftCard;
